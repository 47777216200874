* {
  box-sizing: border-box;
  font-family: Linotte;
}

html {
  font-size: 20px;
}
.space {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url("./pages/assets/space.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 15px;
  }
}

@font-face {
  font-family: Poppins;
  src: url('pages/assets/Poppins-Regular.ttf');
}

@font-face {
    font-family: JungleAdventurer;
    src: url('pages/assets/JungleAdventurer.ttf');
}

@font-face {
    font-family: Linotte;
    src: url('pages/assets/Linotte Regular.otf');
}

@font-face {
    font-family: SuperCreamy;
    src: url('pages/assets/Super Creamy Personal Use.ttf');
}

@font-face {
    font-family: HoneyCrepes;
    src: url('pages/assets/Honey Crepes.ttf');
}