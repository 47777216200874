.game-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #001933;
    margin: 0;
    font-family: Arial, sans-serif;
}
  
#game {
    text-align: center;
}
  
#color-buttons {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
}
  
.color-button {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    cursor: pointer;
    opacity: 0.3;
}
  
#red { background-color: #ff0040; }
#orange { background-color: #ff9500; }
#yellow { background-color: #ffff00; }
#light-green { background-color: #aaff00; }
#green { background-color: #00ff15; }
#cyan { background-color: #00ffff; }
#blue { background-color: #0095ff; }
#purple { background-color: #6a00ff; }
#pink { background-color: #ff00ff; }
  
.color-button.active {
    opacity: 1;
}
  
.gone {
    opacity: 0;
}

#start-button {
    padding: 13px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 25px;
    background-color: #004c99;
    border: none;
    color: #cce5ff;
}
  
#status {
    margin-top: 20px;
    font-size: 40px;
    color: #cce5ff;
}
