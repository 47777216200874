.container {
    background-color: #c7e0ff;
}

.setting {
    font-size: 1rem;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0.5rem;
    height: 60px;
    align-items: center;
    color: black;
}

.setting label {
    font-weight: bold;
    margin-right: 1rem;
}

.setting select{
    background-color: #BAC3FF;
    font-size: 1rem;
}