.container {
  font-family: Arial, sans-serif;
  text-align: center;
  background-color: #c7e0ff;
  height: calc(100vh - 60px);
  color: #2162B1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1 {
  font-family: HoneyCrepes;
  margin: 2rem 0 1rem;
}

.setting {
  color: #2162B1;
  font-size: 1rem;
}

.setting label {
  font-weight: bold;
}

.select {
  margin-left: 0.5rem;
  background-color: #2162B1;
  color: #c7e0ff;
  font-weight: bold;
  font-size: 1rem;
}

.select option {
  font-family: sans-serif;
  font-size: 18px;
}

.question {
  background-color: #2162B1;
  color: white;
  margin: 2rem 0 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 800px;
  border-radius: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.question h2 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0.5rem 0 1rem;
}

form {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

input {
  font-size: 2rem;
  border: 0;
  border-radius: 15px;
  text-align: center;
  width: 300px;
  padding: 0.25rem;
}

button {
  border: 0;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
}

.message {
  font-size: 1rem;
  height: 1rem;
}