.layout {
    min-height: calc(100vh - 60px);
    width: 100%;
    margin: 0;
    top: 60px;
    position: absolute;
    color: white;
    gap: 1rem;
}

select {
    border: 0;
    padding: 3px 10px;
    border-radius: 25px;
}

select option {
    font-family: sans-serif;
}