.gameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: background-color 0.3s;
  padding: 0 1rem 1rem;
  color: white;
  height: calc(100vh - 120px);
  background-color: #C7E0FF;
}

.result {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

.imageContainer {
  height: 100%;
  aspect-ratio: 1 / 1;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  min-width: fit-content;
  align-items: center;
}
.imageContainer img {
  max-width: 100%;
  max-height: 100%;
  width: fit-content;
  height: fit-content;
  border-radius: 1rem;
}

.questionOptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #2162b1;
  min-width: calc(50vw - 1rem);
  width: 100%;
  max-height: 500px;
  height: 100%;
  color: white;
  border: 3px solid #C7E0FF;
  transition: border-color 0.3s;
}

.questionContainer {
  font-size: 1rem;
  text-align: center;
}

.optionsContainer {
  display: grid;
  width: 100%;
  grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  grid-template-rows: 5rem 5rem;
  gap: 0.5rem;
  margin-top: 20px;
}

.optionButton {
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  color: black;
  font-weight: bold;
  background-color: #FCF6BD; /* Green by default */
}

.optionButton:hover {
  background-color: #D0F4DE;
}


.resultContainer {
  height: 3rem;
  margin: 5px 0;
  font-size: 2rem;
  font-weight: bold;
}

.buttonContainer {
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 500px;
  height: 300px;

  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;

  background-color: #2247AF;
  border-radius: 30px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.8);
}

.menuTitle {
  font-size: 1.8rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.menuButtons {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.button {
  font-size: 1.3rem;
  background-color: #FFBD59;
  width: 200px;
  height: 100px;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  color: #2247AF;
  font-weight: bold;
  text-align: center;
  float: center;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: center;
}