.flashcardContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
  grid-template-rows: 600px;
  gap: 1rem;
  align-items: center;
  transition: background-color 0.3s;
  padding: 0 1rem 1rem;
  color: white;
  background-color: #c7e0ff;
}

.flashcard {
    width: 400px;
    min-height: 500px;
    background-color: #E4F0C9;
    display: flex;
    justify-content: top;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    border-radius: 1rem;
}

.flashcard img {
    width: 100%;
    height: fit-content;
    border-radius: 1rem;
}

.flashcard p{
    width: 100%;
    text-align: center;
    color: #2162B1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.answer {
    font-size: 1.5rem;
    font-weight: bold;
}