.home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    top: 60px;
    font-family: Linotte;
    background-image: linear-gradient(180deg, rgba(9,111,161,0.5) 0%, rgba(108,177,186,0.8) 100%), url('assets/space.svg');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}

.filters {
    display: flex;
    justify-content: left;
    gap: 1rem;
    padding: 0.5rem 1rem;
    position: sticky;
    top: 60px;
    z-index: 1000;
    color: white;
}

.label {
    font-weight: bold;
}

.select {
    font-size: 1rem;
    margin-left: 0.5rem;
    border: 0;
    padding: 3px 10px;
    border-radius: 25px;
    background-color: #BAC3FF;
}

.select option {
    font-family: sans-serif;
    font-size: 18px;
}

.category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1rem;
    position: relative;
}

.category h2 {
    margin-bottom: 0.5rem;
    font-family: HoneyCrepes;
    font-size: 2rem;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    margin-top: 0.5rem;
}

.category ul {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
}

.category li {
    margin-bottom: 5px;
    margin: 1rem;
}

.category a {
    text-decoration: none;
    color: #333;
}

.app-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    gap: 10px;
}

.app-name {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    font-family: Linotte;
}

.icon {
    width: 100px;
    height: 100px;
    border-radius: 0.5rem;
}